import { Anchor, List, Title } from '@mantine/core';
import React from 'react';
import './index.less';
import logo from '../images/logo.svg';
import logoInverse from '../images/logo-inverse.svg';
import beian from '../images/beian.png';
import { Link } from 'gatsby';

const initialLinks = [
  { link: '/', name: 'Introduction' },
  {
    name: 'On-site Chemical Productions', children: [
      { link: '/product', name: 'On-site Hydrogen Peroxide Production', value: 0 },
      { link: '/partner', name: 'Partnered with Hydrogen Peroxide Manufacturer', value: 1 },
    ]
  },
  { link: '/cases', name: 'Applications and Cases' },
  { link: '/about', name: 'Contact Us' },
];

const footerLinks = [
  { link: '/', name: 'Introduction' },
  { link: '/#p2x', name: 'Mission of Power-to-X' },
  { link: '/product', name: 'On-site Chemical Productions' },
  { link: '/partner', name: 'Partnered with Hydrogen Peroxide Manufacturer' },
  { link: '/cases', name: 'Applications and Cases' },
  { link: '/about', name: 'Contact Us' },
];

function Layout({ location, children }: { children: React.ReactNode, location: any }) {
  const currentPath = '/' + location.pathname.split('/')[1] + location.search;

  return (<>
    <header>
      <div className="logo-container">
        <img className="logo" src={logo} />
      </div>
      <nav>
        <div className="links-container">
          {initialLinks.map((item, index) => (item.link ?
            <Link
              key={item.link}
              className={currentPath === item.link ? 'active' : ''}
              to={item.link || ''}
            >
              {item.name}
            </Link>
            : <a
              key={item.link}
              className="menu"
              style={{zIndex: 100 - index}}
            >
              {item.name}
              {item.children && (<div className='children'>
                {item.children.map(subItem => (
                  <Link
                    key={subItem.link}
                    className={currentPath === subItem.link ? 'active' : ''}
                    to={subItem.link || ''}
                  >{subItem.name}</Link>
                ))}
              </div>)}
            </a>
          ))}
        </div>
      </nav>
    </header>
    <main>
      {children}
    </main>
    <footer>
      <div className="container">
        <div className="company">
          <div className="logo-container">
            <img className="logo" src={logoInverse} />
          </div>
          <p className="intro">Phase Two Chemicals is a deep technology company specializing in electrochemical systems. The vision of Phase Two Chemicals is to supply high-efficiency electrochemical systems based our in-house developed phase dispersion electrode and reactors for Power-to-X applications. Motivated by carbon neutrality goals, we are focused on large-volume chemicals. Using our innovative products, we will reduce societal reliance on fossil fuels and become part of the carbon dioxide emissions solutions needed in the 21<sup>st</sup> century.</p>
        </div>
        <div className="links">
          <Title className='title' order={2}>Quick Access</Title>
          <List>
            {footerLinks.map(item => (<Anchor
              key={item.link}
              href={item.link}
            >{item.name}</Anchor>))}
          </List>
        </div>
        <div className="contract">
          <Title className="title" order={2}>Contact Us</Title>
          <span>info@chengyangtech.com</span>
          <span>0575-85599858</span>
          <span>021-60560385</span>
          <span>555 Changjiang Road, Boshan District, Shanghai</span>
        </div>
      </div>
      <div className="copyright">
        <div>
          <span>版权所有 &copy; {(new Date()).getFullYear()}</span>
          <span>上海橙氧科技有限公司</span>
          <Link to="/privacy">隐私条款</Link>
        </div>
        <div className='beian'>
          <Anchor href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33060302000783" target="_blank">
            <img src={beian} /> 浙公安网备33060302000783号
          </Anchor>
          <Anchor href="http://beian.miit.gov.cn/" target="_blank">浙ICP备20022661号</Anchor>
        </div>
      </div>
    </footer>
  </>);
}

export default Layout;
